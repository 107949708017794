.App {
  font-family: sans-serif;
  text-align: center;
  min-height: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html,
body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  height: 100%;
}
#root {
  min-height: 100%;
  height: 100%;
}

.term-input {
  position: absolute;
  overflow: hidden;
  opacity: 0;
  height: 0;
  padding: 0;
  width: 0px;
  top: -9999px;
  left: -9999px;
}

.term {
  background: #000;
  width: 100%;
  min-height: 100%;
  color: #aaa;
  font-family: Monaco, monospace;
  font-weight: 400;
  font-smooth: never;
  -webkit-font-smoothing: none;
  font-size: 10.5pt;
  padding: 3px;
  word-wrap: break-word;
  white-space: pre-wrap;
  display: block;
  line-height: 1.2em;
  overflow-y: scroll;
  position: relative;
  color: #888;
  text-align: left;
}

.term-focus {
  text-shadow: none;
  color: #ccc;
}

.term .red {
  color: red;
}
.term .blue {
  color: blue;
}
.term .white {
  color: #fff;
}
.term .bold {
  font-weight: bold;
}

.bell {
  width: 0.1em;
  height: 1.1em;
  line-height: 1.2em;
  background: #fff;
  position: absolute;
  animation: flash 1s infinite;
}

@keyframes flash {
  0% {
    visibility: visible;
  }
  49% {
    visibility: visible;
  }
  50% {
    visibility: hidden;
  }
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #111;
}

textarea.content {
  background: none;
  border: none;
  outline: none;
  resize: none;
  color: white;
}
